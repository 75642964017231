<template>
  <CashCollections></CashCollections>
</template>

<script>
import CashCollections from '../customerSupport/CashCollection.vue'
export default {
  components: {
    CashCollections
  }
}
</script>